import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import ContentWrapper from '../components/share/contentWrapper'
import UnorderedList from '../components/share/unorderedList'
import ImageBackground from '../components/share/imageBackground'
import Layout from '../components/layout'

import customMedia from '../utils/customMedia'

const query = graphql`
query {
  header: file(relativePath: { eq: "realStudios/mediareviewer.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

const PrivacyPage = () => {
  return (
    <Layout>
      <StaticQuery
        query={query}
        render={data => (
          <>
            <ImageBackground
              background={data.header.childImageSharp.fluid}
              opacity="0.6"
              style={{ height: '400px' }}
              skew
            >
              <ContentWrapper>
                <HeaderTitle> 
                  Privacy Policy
                </HeaderTitle>

              </ContentWrapper>
            </ImageBackground>

            <ContentWrapper style={{ marginTop: 30, marginBottom: 0 }}>
              <Paragraph>
                <strong>Realhub Systems (ABN 48 608 435 990)</strong>
              </Paragraph>
              <Paragraph>
                <strong>Email: </strong>support@realhub.com.au
              </Paragraph>
              <Paragraph>
                <strong>Phone: </strong>02 9029 4025
              </Paragraph>
              <Paragraph>
                <strong>Website: </strong>www.realhub.com.au
              </Paragraph>
            </ContentWrapper>

            <ContentWrapper style={{ marginTop: 60, marginBottom: 120 }}>
              <Section>
                <SectionHeader>
                  1. Introduction
                </SectionHeader>

                <Paragraph>
                  We are committed to protecting the privacy of all personal information that we collect from you and
                  to complying with our obligations under the Privacy Act 1988 (Cth) (Privacy Act) and the Australian
                  Privacy Principles (APPs).
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>
                  2. Purpose of collection
                </SectionHeader>

                <Paragraph>
                  We collect your personal information primarily to enable us to provide you with services. We may also
                  use this information to promote or market related products or services to you.
                  If you choose not to provide certain personal information, we may not be able to provide you with the
                  products or services you require, or an appropriate level of service.
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>
                  3. Type of information collected
                </SectionHeader>

                <Paragraph>
                  “Personal information” is information or an opinion about an identified individual or an individual
                  who is reasonably identifiable whether or not the information is correct and not whether it is
                  recorded in a material form or not. 
                </Paragraph>

                <UnorderedList title="The personal information that we collect includes:">
                  <li>name, ACN or ABN, address, contact details, date of birth;</li>
                  <li>payment details;</li>
                  <li>financial institution account details; </li>
                  <li>credit card details; and</li>
                  <li>records of products or services supplied to or received by you.</li>
                </UnorderedList>

                <Paragraph>
                  We may store the personal information we collect from you in various forms, including through an
                  electronic record system. We will comply with the APPs and this Privacy Policy, in respect of your
                  personal information in whatever form that information is stored by us. 
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>
                  4. Methods of collection
                </SectionHeader>

                <UnorderedList title="We will collect personal information directly from you when:">
                  <li>you make an application for, or sign our agreement for, the provision of our products or services; and</li>
                  <li>you request any information regarding our products or services in person, by phone or via the internet.</li>
                </UnorderedList>

                <UnorderedList title="We also use other external service providers for the following purposes:">
                  <li>or web hosting services for this website; and/or</li>
                  <li>to gather non-personal information (using cookies) in order to evaluate the website’s effectiveness, for example online marketing activities.</li>
                </UnorderedList>

                <Paragraph>
                  We may collect cookies from you about your visit to our website to help us to improve our website.
                  Cookies are small information files which are sent to your computer’s hard drive or mobile device
                  when you visit a website and it will recognise your device on future visits. For example, we may
                  collect the time of your visit, whether you have visited our website previously, whether you used
                  a search engine to find us and some geographical information. For information on disabling these
                  cookies, please go to the privacy settings section within your browser
                </Paragraph>

                <Paragraph>
                  We may also use analytical web tools such as Google Analytics to collect visitor information for us
                  to better understand how to improve our products and services for you. In addition to the session
                  cookie, Google Analytics uses other data collection methods such as appending query strings to an
                  image request. We store the data generated by Google Analytics securely and do not share it with third parties. 
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>5. Use</SectionHeader>

                <Paragraph>
                  We will only use your personal information in accordance with the APPs set out in the Privacy Act.
                  We will not sell your personal information to any third party for commercial purposes.
                </Paragraph>

                <UnorderedList title="We may use your personal information for the following purposes:">
                  <li>verifying your identity;</li>
                  <li>providing you with our products or services;</li>
                  <li>providing you with information in relation to the use of our products or services;</li>
                  <li>administering and managing those products or services, including charging, billing and collecting debts;</li>
                  <li>conducting appropriate checks for credit-worthiness;</li>
                  <li>promoting and marketing our products or services to you; </li>
                  <li>internal management purposes, complaint handling, planning, quality assurance and audit purposes;</li>
                  <li>for the purpose of surveys, research and analytics (by us or our third party service providers);</li>
                  <li>to provide products and services to you, to provide you with information about those products and services, to assist you with enquiries or purchases, to enhance your experience on our network, or to provide you with better service; and</li>
                  <li>to gain an understanding of your information and communication needs, to perform research and analysis, and to improve or develop our products and services.</li>
                </UnorderedList>

                <Paragraph>
                  We will not use your personal information for direct marketing purposes unless you have been given
                  an opportunity to opt out of receiving direct marketing communications or unless you have otherwise
                  consented to direct marketing. We will also ensure that you have the opportunity to “opt out” of
                  receiving any further marketing material. 
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>6. Disclosure to others</SectionHeader>

                <UnorderedList title="In providing our products or services, we may disclose your personal information to:">
                  <li>government agencies;</li>
                  <li>your authorised representatives or your legal advisers (when requested by you to do so);</li>
                  <li>credit-reporting and fraud-checking agencies;</li>
                  <li>credit providers (for credit related purposes such as credit-worthiness, credit rating, credit provision and financing);</li>
                  <li>our related companies;</li>
                  <li>a third party organisation, but only in relation to us providing products or services to you (so long as the third party agrees to keep that information confidential);</li>
                  <li>marketing and communication agencies and research and analytics providers – organisations which provide survey, research, analytics and marketing communication services relating to our products and services and our industry; </li>
                  <li>our professional advisers, including our accountants, auditors and lawyers; and</li>
                  <li>Government and regulatory authorities and other organisations, as required or authorised by law.</li>
                </UnorderedList>

                <Paragraph>
                  You can withdraw your consent to our disclosure of your personal information by advising us in writing at any time. 
                </Paragraph>

                <UnorderedList title="We will not disclose your personal information to anyone, other than as set out in this policy, unless:">
                  <li>you authorise us to do so;</li>
                  <li>your safety or the safety of others in the community is at risk; or</li>
                  <li>we are required or permitted to do so by law.</li>
                </UnorderedList>
              </Section>

              <Section>
                <SectionHeader>7. Disclosure to overseas recipients</SectionHeader>

                <Paragraph>
                  Our related entities and some of our service providers (including information technology service
                  providers) may be located outside of Australia and, as a result, personal information collected
                  and held by us may be transferred overseas. The countries in which these recipients may be located
                  will vary from time to time, but may include New Zealand and the United States of America.
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>8. Storage of personal information</SectionHeader>

                <UnorderedList title="We may hold your personal information in a number of ways, including: ">
                  <li>in our computer systems or databases, which may involve storing data on storage or computer systems provided by third party suppliers;</li>
                  <li>in paper records; and/or</li>
                  <li>in telephone recordings.</li>
                </UnorderedList>

                <Paragraph>
                  Where your personal information has been collected from our, or our service providers, they may also
                  hold copies of your personal information. 
                  We may combine personal information we receive about you with other information we hold about you.
                  We will store your personal information in a secure environment where it is protected by a
                  combination of physical and technological measures. We have taken all reasonable steps to carefully
                  protect your data from loss, misuse, unauthorised access or disclosure, alteration or destruction.
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>9. Access</SectionHeader>

                <Paragraph>
                  You have a right to access your personal information. You may request details of your personal
                  information by contacting our Privacy Officer at support@realhub.com.au
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>10. Accuracy and corrections</SectionHeader>

                <Paragraph>
                  We will take reasonable steps to ensure your personal information is accurate, complete and up to
                  date. If you believe that any personal information we hold about you is not accurate, complete or up
                  to date, then please contact our Privacy Officer at support@realhub.com.au
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>11. Security</SectionHeader>

                <Paragraph>
                  We will take all reasonable steps to ensure your personal information is kept secure. 
                </Paragraph>
                <Paragraph>
                  We may enter into arrangements with third parties to store data, including personal information we
                  collect, outside of Australia.  We will take reasonable steps to ensure that the third parties do
                  not breach the APPs.   The steps we will take may include ensuring the third party is bound by
                  privacy protection obligations which are the same (or substantially the same) as those which bind
                  us and requiring that the third party have information security measures approved by us.
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>12. Making a complaint</SectionHeader>

                <Paragraph>
                  If you believe that we have interfered with your privacy in our handling of your personal
                  information, you may contact or lodge a complaint with our Privacy Officer and, if not resolved
                  to your satisfaction, with the Australian Privacy Commissioner. 
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>13. Further information</SectionHeader>

                <Paragraph>
                  For further information regarding this Privacy Policy, please contact our Privacy Officer at
                  support@realhub.com.au
                </Paragraph>
              </Section>

              <Section>
                <SectionHeader>14. Changes and current version</SectionHeader>

                <Paragraph>
                  We may change this Privacy Policy from time to time. This Privacy Policy was last updated in March
                  2017. A copy of the current version of our Privacy Policy is available on our website
                  www.realhub.com.au.
                </Paragraph>
              </Section>
            </ContentWrapper>
          </>
        )}
      />
    </Layout>
  )
}

export default PrivacyPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const Paragraph = styled.p`
  margin-bottom: 10px;
  font-family: Open Sans;
  font-size: 14px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
`

const SectionHeader = styled.h6`
  font-family: Open Sans;
  font-weight: 700;
  margin-bottom: 10px;
`