import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const UnorderedList = (props) => {
  const { children, title } = props

  return (
    <div>
      {title && <Paragraph>{title}</Paragraph>}
    
      <List>  
        {children}
      </List>
    </div>
  )
}

UnorderedList.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default UnorderedList

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 5px;
`

const List = styled.ul`
  margin-left: 30px;
  margin-bottom: 10px;
  font-family: Open Sans;
`